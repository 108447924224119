.mt-1{
  margin-top: 1rem;
}

.mb-1{
  margin-bottom: 1rem;
}

.ml-1{
  margin-left: 1rem;
}

.mr-1{
  margin-right: 1rem;
}

.mt-3{
  margin-top: 3rem;
}

.mt-2{
  margin-top: 2rem;
}

.mb-2{
  margin-bottom: 2rem;
}

.mb-3{
  margin-bottom: 3rem;
}

.ml-3{
  margin-left: 3rem;
}

.mr-3{
  margin-right: 3rem;
}

.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

.txt-align-right {
  text-align: right;
}

.txt-align-left {
  text-align: left;
}

.block{
  display: block;
}

.separator {
  border-bottom: 1px solid rgba(103, 98, 210, 0.50);
}

.separator-gray {
  border-bottom: 1px solid $gray-scale-100;
  margin: 2rem 0 5rem;
}
