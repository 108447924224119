$primary-color: #2F3093;
$secondary-color: #787FF6;
$terciary-color: #7BC5F5;
$text-color: #111827;

$primary-50: #EEF2FF;
$primary-100: #E0E7FF;
$primary-200: #C7D2FE;
$primary-300: #A5B4FC;
$primary-500: #6366F1;
$primary-600: #4F46E5;
$primary-700: #4338CA;
$primary-900: #312E81;

$neutral-50: #F9FAFB;
$neutral-100: #F3F4F6;
$neutral-200: #E5E7EB;
$neutral-300: #D1D5DB;
$neutral-400: #9CA3AF;
$neutral-500: #6B7280;
$neutral-700: #374151;
$neutral-800: #1F2937;
$neutral-900: #111827;

$gray-scale-100: #E8E7E8;
$gray-scale-700: #454348;
$gray-scale-900: #16141A;

$destructive-500-main: #EF4444;

$success-color: #34A853;
$info-color: #4285F4;
$danger-color: #EA4335;
$warning-color: #FBBC04;

$success-50: #F0FDF4;
$success-600: #16A34A;
