/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "variables.scss";
@import "helpers.scss";

body{
  margin: 0;
  padding: 0;
  background-color: white !important;
}

.p-inputtext{
  border: none;
  padding: 0;
  text-align: left;
}

.parragraph {
  color: $primary-color;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
}

.heading-3{
  color: $text-color;
  font-family: Inter;
  font-size: 2.031rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.844rem;
  letter-spacing: -0.041rem;
  margin-bottom: 1rem;
}

.heading-5{
  color: $neutral-900;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.75rem;
  letter-spacing: -0.025rem;
  margin: 1rem 0;
}

.link{
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration-line: underline;
  color: $primary-color;
  cursor: pointer !important;
}

.link-gray{
  color: $neutral-500;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration-line: underline;
  cursor: pointer !important;
}

.btn-primary {
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  border-radius: 0.5rem;
  min-width: 18rem;
  border: 1px solid $primary-500;
  background-color: $primary-500;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.04), 0 1px 2px 0 rgba(16, 24, 40, 0.04);
  & > .p-button-label{
    color: #FFF;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
  }
}

.btn-primary-white {
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid $primary-300;
  background-color: #FFF;
  box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  min-width: 18rem;
  & > .p-button-label{
    color: $primary-300;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
  }
}
.txt-info {
  color: $neutral-500;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
}

.placeholder{
  color: $neutral-400;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.p-inputtext{
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.custom-form, .p-password{
  border-radius: 0.5rem;
  border: 0.063rem solid $neutral-200;
  background: #FFF;
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  color: black;
}

.label-form{
  color: $neutral-900;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 1rem;
  margin-bottom: 0.25rem !important;
  display: block;
}

.p-password > .p-inputtext{
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: left;
  width: 100%;
  color: $neutral-400;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.help {
  color: $neutral-500;
  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

.txt-checkbox, .p-checkbox-label{
  color: $neutral-500;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: flex;
  justify-content: flex-start;
}

.p-checkbox .p-checkbox-box{
  border: 1px solid $neutral-300;
  height: 20px;
  width: 24px;
  margin-left: 0.15rem;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}


.label-wizard {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.text-danger{
  color: $destructive-500-main;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-top: -0.5rem !important;
  margin-bottom: 0.5rem !important;
  display: block !important;
  text-align: left !important;
}

.p-inputwrapper ~ .text-danger {
  margin-top: 0.5rem !important;
}

.custom-form:active, .p-password:active{
  border: 0.063rem solid $primary-300;
}

.custom-form.error, .p-password.error{
  border: 0.063rem solid $destructive-500-main !important;
}

::ng-deep .p-datatable-header{
  background: #FFF !important;
  padding: 0.5rem 1rem !important;
}


@media screen and (min-width: 1960px) {
  .layout-main, .landing-wrapper {
      width: 100% !important;
      margin-left: auto !important;
      margin-right: auto !important;
  }

  .btn-primary-white, .btn-primary {
    min-width: calc(100vw - 4rem) !important;
  }
}
